const sitejabber = {
  sitejabber: [
    {
      "name": "Jim K.",
      "score": "5",
      "date": "3/18/24",
      "title": "Lifesavers!",
      "review": "Stress relief in the form of top-notch tutoring! This platform has been a game-changer for me. Fair pricing, punctuality, and incredible customer support make it my number-one choice.",
      "nerdify": "Thank you, Jim, for taking the time to share your experience with us. Don’t hesitate to contact us if you need more help!"
    },
    {
      "name": "Luther H.",
      "score": "5",
      "date": "4/6/24",
      "title": "Very satisfied",
      "review": " Awesome customer support! Whenever I had a question or needed clarification, the support team was there to guide me. It's awesome when a platform values excellent customer service.",
      "nerdify": "Thanks for your great review, Luther! We're pleased to hear we could meet your expectations!"
    },

    {
      name: "Patrick Brenton",
      score: "5",
      date: "11/22/23",
      title: "Huge thanks",
      review: "I owe a huge thanks to Nerdify for their fantastic service. They assisted me in compiling informative notes for my Marketing project, and I couldn't have done it without them.",
      nerdify: "Patrick, huge thanks for your feedback!"
    },
    {
      name: "Joseph Marksom",
      score: "5",
      date: "10/25/23",
      title: "Recommended",
      review: "I'm so grateful for Nerdify's support! They helped me collect useful data for my presentation, and their friendly customer support answered all my questions 24/7.",
      nerdify: "Thanks, Joseph! Share this experience with friends via your Nerdify referral link and get bonuses!"
    },
    {
      name: "Bradley Stathem",
      score: "5",
      date: "10/17/23",
      title: "Amazing service",
      review: "I can't thank Nerdify enough for their amazing service and friendly customer support. My Nerd was very helpful and helped me to find a book I was looking for the whole month. They are true life-savers!",
      nerdify: "Thank you, Bradley! Hope you already had a chance to use your Nerdify referral link and get bonuses ;)"
    },
    {
      name: "Shawn Lewis",
      score: "5",
      date: "10/07/23",
      title: "Good helper with studies",
      review: "I usually use Nerdify to get tutoring help when I need it, it's much cheaper than other similar websites I found.",
      nerdify: "Thank you, Shawn!"
    },
    {
      name: "Marg Geller",
      score: "5",
      date: "09/16/23",
      title: "I'm impressed!",
      review: "I was really impressed with the service I received from Nerdify. The Nerd James they assigned to me was friendly, responsive, and incredibly knowledgeable. He helped me to do the market research and explained how to prepare the report on it. In addition, I've earned some extra points from my prof.",
      nerdify: "Thanks, Marg! We'll say thanks to James from you :)"
    },
    {
      name: "Gina Awelsom",
      score: "5",
      date: "09/09/23",
      title: "Reliable and trustworthy",
      review: "Nerdify is a reliable and trustworthy service. With their help, I decided on the approach and a detailed plan for my History class research. Also, they found several useful sources I could use for it, and at the end of the day result was top-notch. I will definitely be using them again in the future.",
      nerdify: "Gina, thank you for the review! Don’t hesitate to contact us if you need more help!"
    },
    {
      name: "Laura Amber",
      score: "5",
      date: "09/03/23",
      title: "Awesome results",
      review: "Nerdify helped me with a difficult math assignment, and I couldn't be happier with the results. My Nerd was patient and explained everything clearly, which helped me understand the concepts better.",
      nerdify: "Thanks, Laura! We'll say thanks to the Nerd from you :)"
    },

    {
      name: "Chin M.",
      score: "5",
      date: "01/09/22",
      title: "Great Help!",
      review: "Life sometimes gets hard, and we need a little help. Nerdify definitely helped me, and with due professionalism and quickness."
    },
    {
      name: "Kenai F.",
      score: "5",
      date: "12/29/21",
      title: "Great product",
      review: "They have absolutely amazing services, reasonable prices, and good communication. The project was done on time to exact specifications. No complaints."
    },
    {
      name: "Janay L.",
      score: "5",
      date: "12/20/21",
      title: "Such a lifesaver!",
      review: "Nerdify is truly a lifesaver. The staff you text with are patient and caring, and they clearly take their work seriously. The Nerd who helped with my task did their job to perfection. I recommend this website to anyone and everyone."
    },
    {
      name: "Stefanie S.",
      score: "5",
      date: "12/15/21",
      title: "100% guaranteed!",
      review: "Nerdify is such a smart and helpful business! I was extremely surprised by how easy this entire process was. Not just that, but also how quickly they respond and find \"Nerds\" to work on the tasks! I also liked how they offer features to upgrade the quality of output, which I think is worth it! This website saved me a lot of time and stress. I also perform better because of the help I receive. :) I will recommend this to anyone struggling in any subject!"
    },
    {
      name: "Carrie S.",
      score: "5",
      date: "12/08/21",
      title: "5 star experience",
      review: "Great experience. Awesome customer service. Very quick response time. We are very satisfied and will be repeat customers. Thank you!"
    },
    {
      name: "Aaliyah M.",
      score: "5",
      date: "12/06/21",
      title: "Amazing Work",
      review: "They did a great job! I was hesitant about using Nerdify at first, but I'm very grateful that I gave them a chance. They responded to my questions quickly and stayed true to their word. They finished my request on time."
    },
    {
      name: "Maya L.",
      score: "5",
      date: "12/03/21",
      title: "Professional  work",
      review: "This website finds tutors for me within the exact time frame they promise!"
    },
    {
      name: "Jay C.",
      score: "5",
      date: "12/02/21",
      title: "Experts in their fields",
      review: "Nerdify completed my order in 3-4 days for a cost that was less than a dinner out with my wife. Very thankful, as this class was far beyond my capabilities. I will use them again. If you need help, shoot Nerdify a text, put your efforts towards something you are good at, and let the professionals address the rest."
    },
    {
      name: "Mary S.",
      score: "5",
      date: "12/02/21",
      title: "Legit! Not a scam!",
      review: "This website is legit. It's real. I've been scammed before, but I tried this website and got what I paid for. Nerdify is a good website."
    },
    {
      name: "Shakyra M.",
      score: "5",
      date: "11/30/21",
      title: "Using them again!",
      review: "I have used this website twice now, to get help with tasks for college, and I haven't experienced any issues. They are quick to respond, and they make changes I request smoothly. I will definitely be using this website again."
    },
    {
      name: "Nikki J.",
      score: "5",
      date: "11/29/21",
      title: "OMG! You guys rock!",
      review: "You all do not understand how happy and satisfied I am with the work I received from Nerdify. Not only did they do what was asked, but they also went above and beyond. It was perfect. First off, kudos to my Nerd - I wish I knew their name. Second, the communication is on point. These people quadruple check what you would like, and they also make sure that you are satisfied. I 1000% would recommend Nerdify. The amount of stress taken off my life because I received help from them is beyond belief. Thanks, Nerdify. I can't wait to work with you again. You guys rock!"
    },
    {
      name: "Sabrina K.",
      score: "5",
      date: "11/27/21",
      title: "Amazing experience with Nerdify",
      review: "Super communicative and helpful! Got my task done on time and even earlier! I really recommend Nerdify to everyone!"
    },
    {
      name: "Danica K.",
      score: "5",
      date: "11/26/21",
      title: "Fantastic service!",
      review: "Nerdify helped me complete a big task. They've been fantastic, even if I needed everything done immediately on such short notice."
    },

    {
      name: "Porscha Y.",
      score: "5",
      date: "8/31/2021",
      title: "Makes my life easier!",
      review: "My overall experience has been great! Nerdify makes my college experience a little bit easier since I also work full time. I'm able to pass 5 classes with mentoring from Nerds!",
      nerdify: "Thank you so much for your feedback! Making your life easier is our mission and such reviews inspire us a lot!"
    },
    {
      name: "Rosa G.",
      score: "5",
      date: "6/2/21",
      title: "The best tutors!",
      review: "I took sessions from different teachers, but only Nerds managed to help me so effectively in my courses so, I got better ratings with no fuss. The best tutors are here, don't even hesitate. THANK YOU!",
      nerdify: "Thank YOU, Rosa!"
    },

    {
      name: "Shaun T.",
      score: "5",
      date: "4/27/21",
      title: "100% satisfied",
      review: "Even though there are now many websites that offer tutoring services, I have been loyal to Nerdify for more than a year. These guys never disappointed me and always provided help with whatever course I needed.",
      nerdify: "We are very happy you're satisfied!"
    },
    {
      name: "Anoop S.",
      score: "5",
      date: "4/13/21",
      title: "The best tutors I could find!",
      review: "Since I don't have the opportunity to have tutoring sessions on campus, Nerdify has become my salvation. Their tutors are very professional. In less time than expected, I managed to upgrade my performance. Their prices are more than reasonable, and the service is of high quality!",
      nerdify: "Thank you!"
    },
    {
      name: "Joey B.",
      score: "1",
      date: "12/08/20",
      title: "They're expensive",
      review: "They charge a lot. Do not recommend. Do not use. They are bad.",
      nerdify: "Dear Joey, please specify your email or your order number. I assure your feedback will be passed to our QA team and we will investigate your case."
    },
    {
      name: "Brock I.",
      score: "5",
      date: "12/15/20",
      title: "So good",
      review: "Great work and great service. Super responsive and helpful. I've had no issues with any tasks. It's definitely best used if you send a request a couple of days in advance for a cheaper price, but I've placed super last-minute orders and they've always been right on top. Can't recommend it enough, super easy to use!",
      nerdify: "Thank you so much for this great feedback!"
    },
    {
      name: "Rahul S.",
      score: "5",
      date: "11/18/20",
      title: "They did a great job",
      review: "I had a task for my accounting class and I had no idea how to handle it. I reached out to Nerdify and they did a great job with finding a proper Nerd for me. It was definitely worth it and would definitely recommend Nerdify!",
      nerdify: "Thank you, Rahul!"
    },
    {
      name: "Jasmine B.",
      score: "5",
      date: "11/03/20",
      title: "AMAZING",
      review: "Wow. So many things I could say about this website but overall - such a great help! Would definitely use these services again soon!",
      nerdify: "Thank you, Jasmine!"
    },
    {
      name: "Marlen D.",
      score: "5",
      date: "10/19/20",
      title: "Extremely Helpful",
      review: "I extremely recommend their services. I've used it a couple of times and I always get excellent experience! Very helpful!"
    },
    {
      name: "Tonya W.",
      score: "5",
      date: "9/09/20",
      title: "Extremely helpful and a lifesaver!",
      review: "This experience was so easy and seamless! I finally can breath a sigh of relief with my already beyond hectic schedule. I'm a single mother of 3 seeking my higher education so every day life is super stressful. The Nerds at Nerdify were amazing, helpful, sincere and even stayed on the phone with me until 1:30 am doing everything possible to help me with my tasks. I recommend Nerdify to anyone truly needing really honest and thorough assistance with  tasks. Thanks, Nerdify!"
    },
    {
      name: "Britney K.",
      score: "5",
      date: "9/09/20",
      title: "Great service ever",
      review: "I am really happy with my first experience of the Nerdify. They provide fast, high quality and great service. Highly recommended!"
    },
    {
      name: "AJ A.",
      score: "5",
      date: "8/31/20",
      title: "Great",
      review: "Great customer service! They are so helpful. I highly recommend their service to anyone struggling to balance work and school!",
      nerdify: "Thanks for your feedback! Would you mind changing the rating, though? :) I bet you wanted to give us... 5 stars ;)read more"
    },
    {
      score: "5",
      name: "Tasha C.",
      title: "Best company ever!",
      review: "I gave them 2 tasks I needed help with. I love them as they stayed in touch with me and made sure that everything was perfect. Listen, if you need help, they will help with any tasks. You won't regret it plus I bet they have the best prices.",
      nerdify: "",
      date: "8/05/20"
    },
    {
      score: "5",
      name: "Sarah d.",
      title: "Excellent service with accurate, professional work",
      review: "I have used Nerdify to help me with my college tasks when I was in a bind or needed some additional help. Every time they met all requirements. They provided professional assistance in the expected time. I will continue to use their services with peace of mind.",
      nerdify: "",
      date: "7/22/20"
    },
    {
      score: "5",
      name: "Makenson P.",
      title: "Great Experience!",
      review: "My experience with Nerdify was absolutely amazing. I would definitely recommend them to anyone who needs help.",
      nerdify: "",
      date: "4/05/20"
    },
    {
      score: "1",
      name: "AJ A.",
      title: "Great!",
      review: "Great customer service and they are so helpful. I highly recommend the service to anyone struggling to balance work and school!",
      nerdify: "Thanks for your feedback! Would you mind changing the rating, though? :) I bet you wanted to give us 5 stars ;)",
      date: "3/15/20"
    },
    {
      score: "5",
      name: "Alex V.",
      title: "These guys are really good :)",
      review: "Awesome company. So quick to respond! Helped me with my task in less than 2 days! Unbelievable! They are the only company that I came across that consistently delivers flawless service. I will stick with these guys :)",
      nerdify: "",
      date: "2/18/18"
    },
    {
      score: "5",
      name: "Valushka C.",
      title: "I am so lucky I've found Nerdify",
      review: "What can I say? Great customer service - they are super quick to respond. All I had to do is send them the requirements. What else can I say - so lucky I've found Nerdify. Will use them again for sure.",
      nerdify: "",
      date: "12/19/17"
    },
    {
      score: "2",
      name: "Dixie D.",
      title: "Disappointed",
      review: "I consulted Nerdify about presentation and gave them 4 days to work on this. They handed me the presentation two hours after it was due and I had no time to review it. If I could, I would fix minor things and it would be soooo much better.",
      nerdify: "Dear Dixie, we are extremely sorry to hear that you've a bad experience! It is the opposite of what we want you to have with Nerdify. From what I see in our client management system, a refund was issued back to your PayPal account. We will take all measures to prevent such issues from happening in future. We are extremely sorry for all the stress that this issue has caused. Have a good day.",
      date: "11/05/17"
    },
    {
      score: "5",
      name: "Michael M.",
      title: "Perfect for me",
      review: "I am an international student in USA. Sometimes I have tough tasks on my English class. Nerdify helped me out a lot so far to understand how to complete them. Really saved me lots of time and stress, while I concentrate on classes that matter to me:) Love them!",
      nerdify: "",
      date: "10/27/17"
    },
    {
      score: "5",
      name: "Sandy N.",
      title: "Fast excellent service!",
      review: "Speedy, answers any questions you have inquiring, and great results in whatever you need them to do. I'd highly recommend!",
      nerdify: "",
      date: "5/09/17"
    },
    {
      score: "5",
      name: "Cho G.",
      title: "Helped a lot!",
      review: "I have been using Nerdify for a year now. They helped me adjust to US education by not only explaining how to make my works but also pointing out my mistakes. Thank you so much!",
      nerdify: "",
      date: "7/30/16"
    },
  ],
};

export default sitejabber;
