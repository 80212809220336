import React, { Fragment, useState } from 'react';
import classnames from 'classnames';

import './index.css';

import Cards from './Cards';
import CardsMobile from './CardsMobile';
import { useMobile } from '../../../../hooks';

import imgFb from './img/fb.png';
import imgFbActive from './img/fb-active.png';
import imgTp from './img/tp.png';
import imgTpActive from './img/tp-active.png';

const Switch = ({ value, onChange, switchType }) => {
  return (
    <div className="switch">
      <div
        className={classnames('switchItem', { active: value === 'facebook' })}
        onClick={() => onChange('facebook')}
      >
        {switchType === 1 ? (
          <Fragment>Facebook</Fragment>
        ) : (
          <img
            src={value === 'facebook' ? imgFbActive : imgFb}
            className="imgFb"
            alt=""
          />
        )}
      </div>
      <div
        className={classnames('switchItem', { active: value === 'trustpilot' })}
        onClick={() => onChange('trustpilot')}
      >
        {switchType === 1 ? (
          <Fragment>Trustpilot</Fragment>
        ) : (
          <img
            src={value === 'trustpilot' ? imgTpActive : imgTp}
            className="imgTp"
            alt=""
          />
        )}
      </div>
    </div>
  );
};

const DemoCardDots = (props) => {
  const { active } = props;

  return (
    <div className="demo-card-dots">
      {[...new Array(3)].map((item, key) => {
        return (
          <div key={`demo-card-${key}`}
            className={classnames('demo-card-dot', {
              'demo-card-dot_active': key === active % 3,
            })}
          />
        );
      })}
    </div>
  );
};

const DemoCardsBase = (props) => {
  const { records, title, titleTag, className, indent, subTheme, switchType, activeItem } = props;
  const REVIEW_SOURCES = Object.keys(records);
  const sourceByRecords = REVIEW_SOURCES[activeItem && activeItem ==='trustpilot' ? 1 : 0];
  const TitleTag = titleTag ? titleTag : 'div';
  const [source, setSource] = useState(sourceByRecords);

  const onSourceChange = (source) => {
    setSource(source);
  };

  return (
    <div className={classnames('demoCards', className, `demoCards_${subTheme}`, indent)}>
      <div
        className={classnames(
          'holder',
          'holder_1',
          { holderFb: source === 'facebook' },
          { holderTp: source === 'trustpilot' }
        )}
      >
        {/* SELECT APPROPRIATE COMPONENT FOR HEADER */}
        <TitleTag className="headerMain">
          {title ? title : 'More than 4,800 positive reviews since 2015'}
        </TitleTag>
        <Switch
          values={REVIEW_SOURCES}
          value={source}
          onChange={onSourceChange}
          switchType={switchType}
        />
        {props.children({
          source,
        })}
      </div>
    </div>
  );
};

const DemoCards = (props) => {
  const isMobile = useMobile();
  const { records } = props;

  return (
    <DemoCardsBase {...props} className={'demoCards_0'}>
      {({ source, ...rest }) => {
        return (
          <Fragment>
            {!isMobile && <Cards source={source} records={records} key={`${source}`} />}
            {isMobile && <CardsMobile source={source} records={records} key={`${source}-mob`} />}
          </Fragment>
        );
      }}
    </DemoCardsBase>
  );
};

const DemoCards1 = (props) => {
  const { records } = props;
  const isMobile = useMobile();

  return (
    <DemoCardsBase {...props} className={'demoCards_1'} switchType={1}>
      {({ source }) => (
        <Cards source={source} records={records} isMobile={isMobile} key={`${source}`}>
          {({ active }) => {
            return <Fragment>{isMobile && <DemoCardDots active={active} />}</Fragment>;
          }}
        </Cards>
      )}
    </DemoCardsBase>
  );
};

export { DemoCards as default, DemoCards1 };
