import React, { Component } from 'react';
import { NavLink, Link } from 'react-router-dom';
import Holder from '../../../../themes/nerdify/components/Holder';
import CashbackBanner from './../../../../themes/nerdify/components/Header/CashbackBanner';
import {
  Header1,
  HeaderIcon,
} from './../../../../themes/nerdify/components/Header';
import { Hnav4 } from "../../blocks/Hnav";
import { HnavMob4 } from '../../blocks/HnavMob';
import SaveTime, {SaveTimeIconImg} from '../../../../themes/nerdify/components/SaveTime';
import OurPrices from '../../../../themes/nerdify/components/OurPrices';
import PayList from '../../../../themes/nerdify/components/PayList';
import Text from '../../../../base_components/Text';
import {
  Hiw7,
  HiwImg,
  HiwList,
  HiwItem,
  HiwText,
} from '../../../../themes/nerdify/components/Hiw';

import * as sliderItems from '../../blocks/SlickSlider1';
import SlickSlider1 from '../../../../themes/nerdify/components/SlickSlider1';

import * as sliderCombinedItems from '../../blocks/SliderCombined';
import SliderCombined from '../../../../themes/nerdify/components/SliderCombined';

import * as demoCardsRecords from '../../blocks/DemoCards';
import DemoCards from '../../blocks/DemoCards';

import { demoChatItems } from "../../blocks/DemoChat";
import DemoChat from '../../../../themes/nerdify/components/DemoChat';

import {
  SectionBlock,
  Section11,
} from '../../../../themes/nerdify/components/Section';
import { Hero12 } from '../../../../themes/nerdify/components/Hero';
import { Intro4 } from '../../../../themes/nerdify/components/Intro';
import { Footer2 } from '../../blocks/Footer';
import Press, {
  PressList,
  PressItem,
  PressIcon,
} from '../../../../themes/nerdify/components/Press';

import * as FAQ_Items from "../../blocks/FAQ";
import { Faq4, Accordion, AccordionHead, AccordionBody, AccordionItem, AccordionIcon } from '../../../../themes/nerdify/components/Faq';

import Icon from '../../../../themes/nerdify/components/Icons';
import { TextBack3, TextBack8 } from '../../blocks/TextBack';
import { Nerds2 } from '../../../../themes/nerdify/components/Nerds';
import { TopNerdsInfo3 } from '../../../../themes/nerdify/components/TopNerdsInfo';
import DemoSubjects from '../../../../themes/nerdify/components/DemoSubjects';

import { useMobile } from '../../../../hooks';

import LocalesPage from '../LocalesPage';
import BottomModal from './../../../../components/BottomModal';

const WithHook = (props) => {
  const isMobile = useMobile();

  return props.children({
    isMobile,
  });
};

class Landing7 extends Component {
  constructor(props) {
    super(props);
    this.openHiwModal = this.openHiwModal.bind(this);
    this.closeHiwModal = this.closeHiwModal.bind(this);
  }

  openHiwModal() {
    this.howItWorksModalRef.show();
  }

  closeHiwModal() {
    this.howItWorksModalRef.hide();
  }

  render() {
    const { pageCfg } = this.props;
    const FAQs = pageCfg.FAQ_Items ? FAQ_Items[pageCfg.FAQ_Items] : FAQ_Items.FAQItems2;

    return (
      <WithHook>
        {({ isMobile }) => {
          return (
            <LocalesPage {...this.props}>
              {pageCfg.cashbackBanner && <CashbackBanner />}
              <div className="landing">
                <Header1>
                  <Holder>
                    <NavLink className="logo-link" to="/">
                      <HeaderIcon iconName="logo-dark" />
                    </NavLink>
                    <div className="header-wrap">
                      {isMobile ? <HnavMob4 /> : <Hnav4 />}
                    </div>
                  </Holder>
                </Header1>

                <Hero12>
                  <div className="hero-main">
                    <div className="holder">
                      <h1 className="hero__title">
                        {pageCfg.headline || (
                          <Text>Confused by your Homework?</Text>
                        )}
                      </h1>
                      <h2 className="hero__subtitle">
                        {pageCfg.subtitle || (
                          <Text>
                            Get instant access to Nerds
                            <br className="mobile-only" /> who can help
                          </Text>
                        )}
                      </h2>
                      <TextBack3 {...this.props} subTheme={6} submitBtnTxt={'Text me now'} />
                    </div>
                  </div>
                  <div className="hero-rate">
                    <div className="holder">
                      <h2 className="hero-rate-msg">
                        76,750 users rated Nerds 4.7/5
                      </h2>
                      <div className="hero-rate-icon" />
                    </div>
                  </div>
                </Hero12>
                <Hiw7 subTheme={1}>
                  <div className="holder hiw-holder1">
                    <h2 className="h3">
                      Fast. Simple.
                      <br className="mobile-only" /> Always with you.
                    </h2>
                    <div className="desktop-only">
                      <HiwList>
                        <HiwItem>
                          <HiwImg img={'hiw1'} />
                          <HiwText>
                            <p className="p">
                              Tell us what you need help with or send a pic with
                              instructions
                            </p>
                          </HiwText>
                        </HiwItem>
                        <HiwItem>
                          <HiwImg img={'hiw2'} />
                          <HiwText>
                            <p className="p">
                              Get the best-fit Nerd assigned for your task as
                              soon as possible
                            </p>
                          </HiwText>
                        </HiwItem>
                        <HiwItem>
                          <HiwImg img={'hiw3'} />
                          <HiwText>
                            <p className="p">
                              Agree to the quote and make a secure payment through
                              the platform
                            </p>
                          </HiwText>
                        </HiwItem>
                        <HiwItem>
                          <HiwImg img={'hiw20'} />
                          <HiwText>
                            <p className="p">
                              Get help and become one more happy{' '}
                              <br className="desktop-only" /> Nerdify client
                            </p>
                          </HiwText>
                        </HiwItem>
                      </HiwList>
                    </div>
                    <div className="hiw-subtitle mobile-only">
                      Drop us a simple message or a picture with your task &amp;
                      get best Nerd assigned
                    </div>
                    <div className="hiw-demochat">
                      <DemoChat items={ demoChatItems } />
                    </div>

                    <button
                      className="button__type14 mobile-only"
                      type="button"
                      onClick={this.openHiwModal}
                    >
                      <Icon className={`svg-icon`} iconName="small-arrow" />
                      <div>Learn more details</div>
                    </button>

                    <BottomModal
                      ref={(ref) => {
                        this.howItWorksModalRef = ref;
                      }}
                    >
                      <div className="hiw-bottom-modal">
                        <button
                          className="hiw-modal-close"
                          onClick={this.closeHiwModal}
                        >
                          <Icon className="svg-icon" iconName="close-icon3" />
                        </button>

                        <h3 className="h3 mobile-only">Here is how it works</h3>
                        <HiwList>
                          <HiwItem>
                            <HiwImg img={'hiw1'} />
                            <HiwText>
                              <p className="p">
                                Tell us what you need help with or send a pic
                                with instructions
                              </p>
                            </HiwText>
                          </HiwItem>
                          <HiwItem>
                            <HiwImg img={'hiw2'} />
                            <HiwText>
                              <p className="p">
                                Get the best-fit Nerd assigned for your task as
                                soon as possible
                              </p>
                            </HiwText>
                          </HiwItem>
                          <HiwItem>
                            <HiwImg img={'hiw3'} />
                            <HiwText>
                              <p className="p">
                                Agree to the quote and make a secure payment
                                through the platform
                              </p>
                            </HiwText>
                          </HiwItem>
                          <HiwItem>
                            <HiwImg img={'hiw20'} />
                            <HiwText>
                              <p className="p">
                                Get help and become one more happy{' '}
                                <br className="desktop-only" /> Nerdify client
                              </p>
                            </HiwText>
                          </HiwItem>
                        </HiwList>
                      </div>
                    </BottomModal>
                  </div>

                  <div className="hr_4" />

                  <div className="holder hiw-holder2">
                    <div className="p__tiny hiw-honor-line">Nerds can do anything to help with your college or work life, <br className="desktop-only" />as long as it's legal and doesn't violate any policy or our <Link className="a__underline" to="/honor-code">Honor Code</Link>. See <Link className="a__underline" to="/honor-code#prohibited-tasks">the list</Link> of prohibited tasks.</div>
                  </div>
                </Hiw7>

                <Press className={'press__2 press__2_1'}>
                  <div className="press-wrapper">
                    <div className="p p__title press__title">Featured on</div>

                    <PressList>
                      <PressItem>
                        <PressIcon iconName={'forbes'} />
                      </PressItem>
                      <PressItem>
                        <a
                          href="https://www.wired.com/story/xai-meeting-ai-chatbot/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <PressIcon iconName={'wired'} />
                        </a>
                      </PressItem>
                      <PressItem>
                        <a
                          href="https://tech.co/startups-redefining-educational-space-2017-04"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <PressIcon iconName={'tech-co'} />
                        </a>
                      </PressItem>
                      <PressItem>
                        <a
                          href="https://www.nextmedia-hamburg.de/wp-content/uploads/2018/12/nextMedia.Kompass_Trendreport_Oktober_2016.pdf"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <PressIcon iconName={'next-media'} />
                        </a>
                      </PressItem>
                      <PressItem>
                        <a
                          href="https://venturebeat.com/2017/05/19/nerdify-announces-the-launch-of-nerdy-bot/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <PressIcon iconName={'venturebeat'} />
                        </a>
                      </PressItem>
                    </PressList>
                  </div>
                </Press>

                <SaveTime className="save-time-block">
                  <div className="holder">
                    <h2 className="h3 save-time-title">Save Time and Effort<br className="mobile-only"/> with Nerdify</h2>
                    <div className="save-time-wrapper">

                      <ul className="save-time-list">
                        <li className="list-title">Spend less time...</li>
                        <li className="list-item">
                          <SaveTimeIconImg className="list-item-img" img="red-cross.svg"/>
                          <div className="list-item-msg">No confusing forms to fill in</div>
                        </li>
                        <li className="list-item">
                          <SaveTimeIconImg className="list-item-img" img="red-cross.svg"/>
                          <div className="list-item-msg">No lengthy search for the right freelancer</div>
                        </li>
                        <li className="list-item">
                          <SaveTimeIconImg className="list-item-img" img="red-cross.svg"/>
                          <div className="list-item-msg">No stressful price negotiations</div>
                        </li>
                      </ul>

                      <ul className="save-time-list">
                        <li className="list-title">…get better results</li>
                        <li className="list-item">
                          <SaveTimeIconImg className="list-item-img" img="green-check.svg"/>
                          <div className="list-item-msg">Instant reply to any request</div>
                        </li>
                        <li className="list-item">
                          <SaveTimeIconImg className="list-item-img" img="green-check.svg"/>
                          <div className="list-item-msg">Just drop a photo, video or text with a task</div>
                        </li>
                        <li className="list-item">
                          <SaveTimeIconImg className="list-item-img" img="green-check.svg"/>
                          <div className="list-item-msg">Live updates via SMS for free</div>
                        </li>
                      </ul>

                    </div>
                  </div>
                </SaveTime>

                <Section11>
                  <TopNerdsInfo3 titleTag={"h2"}/>

                  <DemoSubjects />

                  <Nerds2 indent={'pt50 pb40'}>
                    <div className='nerds__title'>...from some of the <span className="strong">TOP universities</span></div>
                  </Nerds2>
                </Section11>

                <SlickSlider1
                  titleTag={"h2"}
                  items={ pageCfg.slider1Items ? pageCfg.slider1Items : sliderItems.slider1Items5a }
                />

                <Intro4 indent={"pt40m pt60d pb40m pb30d"}>
                  <Holder className={"holder_3"}>
                    <div className="intro__title">Disclaimer</div>
                    <div className="intro__msg">Please be aware that Nerds will not agree to be engaged in the activity that violates any policy or our Honor Code. Please read our  <NavLink className="a__underline" to="/honor-code">Honor Code</NavLink> and <NavLink className="a__underline" to="/honor-code#prohibited-tasks">the list</NavLink> of prohibited tasks carefully.</div>
                  </Holder>
                </Intro4>

                <div className="hr_6" />

                <SliderCombined theme={1} indent={"pt40m pt30d pb40"}
                    titleTag={'h2'}
                    title={<Text>Not sure what to start with?<br className="desktop-only"/> Take a look at the most popular requests!</Text>}
                    items={ pageCfg.sliderCombinedItems ? sliderCombinedItems[pageCfg.sliderCombinedItems] : sliderCombinedItems.SliderCombinedItems }
                />

                <SectionBlock theme={2} indent="pb80m pt70d pb70d">
                  <Holder className="holder_3">
                    <div className='section-title'>…or send your personal help request<br className="desktop-only"/> and we'll text you<br
                      className="mobile-only"/> back in 10 sec</div>
                    <TextBack8 {...this.props} submitBtnTxt={'Text me now'} />
                  </Holder>
                </SectionBlock>

                <DemoCards titleTag={'h2'} records={ pageCfg.demoCards ? demoCardsRecords[pageCfg.demoCards] : '' } />

                <OurPrices />

                <PayList />

                <Faq4>
                  <Holder>
                    <h2 className='h3'>FAQ</h2>
                    <Accordion>
                      {
                        FAQs.map((item, index) => {
                        return (
                          <AccordionItem index={index} key={`faqItem${index}`}>
                            <AccordionHead>
                              <AccordionIcon icons={['plus', 'minus']} />
                              <h3 className='h6'>{item.Q}</h3>
                            </AccordionHead>
                            <AccordionBody>
                              <p className='p'>{item.A}</p>
                            </AccordionBody>
                          </AccordionItem>
                        )
                      })}
                    </Accordion>
                  </Holder>
                </Faq4>

                <Footer2 />
              </div>
            </LocalesPage>
          );
        }}
      </WithHook>
    );
  }
}

export default Landing7;
