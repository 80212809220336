import React from 'react';
import { NavLink } from 'react-router-dom';
import { HnavMob1Theme, HnavMob2Theme, HnavMob3Theme } from './../../../../themes/nerdify/components/HnavMob';

const HnavMobContent = (props) => {
  return (
    <div className='hnav__list'>
      <div className="hnav__sublist-main">
        <div className='hnav__item' onClick={props.onClickClose}>
          <NavLink to='/how-it-works' className={'hnav__link'}>How It Works</NavLink>
        </div>
        <div className='hnav__item' onClick={props.onClickClose}>
          <NavLink to='/top-nerds' className={'hnav__link'}>TOP Nerds</NavLink>
        </div>
        <div className='hnav__item' onClick={props.onClickClose}>
          <NavLink to='/for-students' className={'hnav__link'}>For Students</NavLink>
        </div>
        <div className='hnav__item' onClick={props.onClickClose}>
          <NavLink to='/for-businesses' className={'hnav__link'}>For Businesses</NavLink>
        </div>
        <div className='hnav__item' onClick={props.onClickClose}>
          <NavLink to='/for-academia' className={'hnav__link'}>For Academia</NavLink>
        </div>
        <div className='hnav__item' onClick={props.onClickClose}>
          <NavLink to='/about' className={'hnav__link'}>About Us</NavLink>
        </div>
      </div>
      <div className="hnav__sublist-secondary">
        <div className='hnav__item' onClick={props.onClickClose}><NavLink className='hnav__link' to='/terms-and-conditions'>Terms of Use</NavLink></div>
        <div className='hnav__item' onClick={props.onClickClose}><NavLink className='hnav__link' to='/privacy-policy'>Privacy Policy</NavLink></div>
{/*
        <div className='hnav__item' onClick={props.onClickClose}><NavLink className='hnav__link' to='/cookies'>Cookie Policy</NavLink></div>
        <div className='hnav__item' onClick={props.onClickClose}><NavLink className='hnav__link' to='/refunds'>Refund Policy</NavLink></div>
        <div className='hnav__item' onClick={props.onClickClose}><NavLink className='hnav__link' to='/honor-code'>Honor Code</NavLink></div>
        <div className='hnav__item' onClick={props.onClickClose}><NavLink className='hnav__link' to='/report-abuse'>Report Abuse</NavLink></div>
*/}
      </div>
    </div>
  )
};

const HnavMobContent2 = (props) => {
  return (
    <div className='hnav__list'>
      <div className="hnav__sublist-main">
        <div className='hnav__item' onClick={props.onClickClose}>
          <NavLink to='/how-it-works' className={'hnav__link'}>How it works</NavLink>
        </div>
        <div className='hnav__item' onClick={props.onClickClose}>
          <NavLink className='hnav__link' to='/top-nerds'>TOP Nerds</NavLink>
        </div>
        <div className='hnav__item' onClick={props.onClickClose}>
          <NavLink className='hnav__link' to='/about'>About Us</NavLink>
        </div>
      </div>
      <div className="hnav__sublist-secondary">
        <div className='hnav__item' onClick={props.onClickClose}><NavLink className='hnav__link' to='/terms-and-conditions'>Terms of Use</NavLink></div>
        <div className='hnav__item' onClick={props.onClickClose}><NavLink className='hnav__link' to='/privacy-policy'>Privacy Policy</NavLink></div>
      </div>
    </div>
  )
};

export const HnavMob1 = () => {
  return (
    <HnavMob1Theme>
      {(props) => {
        return <HnavMobContent {...props} />
      }}
    </HnavMob1Theme>
  )
};

export const HnavMob2 = () => {
  return (
    <HnavMob2Theme>
      {(props) => {
        return <HnavMobContent {...props} />
      }}
    </HnavMob2Theme>
  )
};

export const HnavMob3 = () => {
  return (
    <HnavMob3Theme>
      {(props) => {
        return <HnavMobContent {...props} />
      }}
    </HnavMob3Theme>
  )
};

export const HnavMob4 = () => {
  return (
    <HnavMob2Theme>
      {(props) => {
        return <HnavMobContent2 {...props} />
      }}
    </HnavMob2Theme>
  )
};
