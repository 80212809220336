import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
//import classnames from 'classnames';

import { Hanv3Theme, Hanv4Theme } from '../../../../themes/nerdify/components/Hnav';
//import Dropdown from './../../../../themes/nerdify/components/Dropdown';

//import {  } from './../../../../redux/actions/';

class HanvContent extends Component {
  render () {
    return (
      <div className='hnav__list'>
        <div className='hnav__item'>
          <NavLink to='/how-it-works' className={'hnav__link'}>How It Works</NavLink>
        </div>
        <div className='hnav__item'>
          <NavLink to='/top-nerds' className={'hnav__link'}>TOP Nerds</NavLink>
        </div>
        <div className='hnav__item'>
          <NavLink to='/for-students' className={'hnav__link'}>For Students</NavLink>
        </div>
        <div className='hnav__item'>
          <NavLink to='/for-businesses' className={'hnav__link'}>For Businesses</NavLink>
        </div>
        <div className='hnav__item'>
          <NavLink to='/for-academia' className={'hnav__link'}>For Academia</NavLink>
        </div>
        <div className='hnav__item'>
          <NavLink to='/about' className={'hnav__link'}>About Us</NavLink>
        </div>
{/*
        <div className='hnav__item'>
          <Dropdown
            items={[
              {
                name: 'For students',
                link: '/for-students',
              },
              {
                name: 'For businesses',
                link: '/for-businesses',
              },
              {
                name: 'For colleges',
                link: '/for-colleges',
              }
            ]}
          />
        </div>
*/}
      </div>
    )
  }
}


class HanvContent2 extends Component {
  render () {
    return (
      <div className='hnav__list'>
        <div className='hnav__item'>
          <NavLink to='/how-it-works' className={'hnav__link'}>HOW IT WORKS</NavLink>
        </div>
        <div className='hnav__item'>
          <NavLink to='/about' className={'hnav__link'}>ABOUT US</NavLink>
        </div>
        <div className='hnav__item'>
          <NavLink to='/become' className={'hnav__link'}>BECOME A NERD</NavLink>
        </div>
        <div className='hnav__item'>
          <NavLink to='/top-nerds' className={'hnav__link'}>TOP NERDS</NavLink>
        </div>
      </div>
    )
  }
}


/*
export const Hnav1 = () => {
  return (
    <Hanv1Theme>
      <HanvContent />
    </Hanv1Theme>
  )
};

export const Hnav2 = () => {
  return (
    <Hanv2Theme>
      <HanvContent />
    </Hanv2Theme>
  )
};
*/

export const Hnav3 = (props) => {
  return (
    <Hanv3Theme theme={props.theme}>
      <HanvContent />
    </Hanv3Theme>
  )
};

export const Hnav4 = (props) => {
  return (
    <Hanv3Theme theme={props.theme}>
      <HanvContent2 />
    </Hanv3Theme>
  )
};

export const HnavClp3 = () => {
  return (
    <Hanv4Theme>
      <HanvContent2 />
    </Hanv4Theme>
  )
};
