import React, { Component, Fragment } from 'react';
//import classnames from 'classnames';

import imgTpAvatar from './img/tp-avatar.png';
import img5 from './img/5-stars.png';
import img4 from './img/4-stars.png';
import img3 from './img/3-stars.png';
import img2 from './img/2-stars.png';
import imgReply from './img/reply.png';
import imgRecommend from './img/recommend.png';
import imgRecommendActive from './img/recommend-active.png';
import imgEarth from './img/earth.png';
import imgAvaNer from './img/ava-ner.png';

import ReadMoreText from './ReadMoreText';
import VerifiedPurchase from './VerifiedPurchase';

const IMG_STARS = {
  '5': img5,
  '4': img4,
  '3': img3,
  '2': img2,
};

class Item extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasReadMore: false,
      expanded: false,
    };

    this.onTextSetup = this.onTextSetup.bind(this);
    this.onMoreClick = this.onMoreClick.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.level && this.props.level) {
      this.setState({ expanded: false }, () => {});
    }
  }

  onMoreClick() {
    this.setState((state) => {
      return { expanded: !state.expanded };
    });
  }

  onTextSetup(hasReadMore) {
    const { value } = this.props;
    this.setState({ hasReadMore: hasReadMore || value.response });
  }

  renderContentFb() {
    const { source, value } = this.props;
    const { expanded } = this.state;
    if (source !== 'facebook') {
      return null;
    }

    return (
      <Fragment>
        <div className="header">
          <img
            className="avatar"
            src={require(`./photos/${value.photo}`)}
            srcSet={`${require(`./photos/${value.photo}`)} 1x${value.photo2x ? ', ' + require(`./photos/${value.photo2x}`) + ' 2x': ''}`}
            alt={value.name}
          />
          <div className="headerContent">
            <div className="headerNameRow">
              <span className="headerName headerBold">{value.name}</span>
              <img
                src={value.recommends ? imgRecommendActive : imgRecommend}
                className="imgRecommend"
                alt=""
              />
              <span className="headerName">
                {value.recommends ? 'recommends' : "doesn't recommend"}{' '}
                <span className="headerBold">Nerdify</span>
              </span>
            </div>
            <div className="headerDate">
              {value.date}
              {' • '}
              <img src={imgEarth} className="imgEarth" alt="" />
            </div>
          </div>
        </div>
        <ReadMoreText
          textClassName="text"
          containerClassName="textContainer"
          height={110}
          onSetup={this.onTextSetup}
          expanded={expanded}
        >
          {value.review}
        </ReadMoreText>
      </Fragment>
    );
  }

  renderContentTp() {
    const { source, value } = this.props;
    const { expanded } = this.state;
    if (source !== 'trustpilot') {
      return null;
    }

    return (
      <Fragment>
        <div className="header">
          <img src={imgTpAvatar} className="avatar" alt="" />
          <p className="name">{value.name}</p>
        </div>
        <div className="subHeader">
          <img src={IMG_STARS[value.mark]} className="stars" alt="" />
          <p className="date">{value.date}</p>
        </div>
        <p className="title">{value.title}</p>
        <ReadMoreText
          textClassName="text"
          containerClassName="textContainer"
          height={100}
          onSetup={this.onTextSetup}
          expanded={expanded}
        >
          {value.review}
        </ReadMoreText>
      </Fragment>
    );
  }

  renderReadMore() {
    const { hasReadMore, expanded } = this.state;
    const moreText = !expanded ? 'Read more' : 'Read less';
    return (
      <div className="readMore">
        {hasReadMore && (
          <button
            className="readMoreButton"
            type="button"
            onClick={this.onMoreClick}
          >
            {moreText}
          </button>
        )}
        {!hasReadMore && <span />}
        <VerifiedPurchase />
      </div>
    );
  }

  renderReplyFb() {
    const { source, value } = this.props;
    const { expanded } = this.state;

    if (source !== 'facebook' || !value.response || !expanded) {
      return null;
    }

    return (
      <Fragment>
        <div className="responseLine" />
        <div className="response">
          <img src={imgAvaNer} className="imgAvaNer" alt="" />
          <div className="responseContent">
            <p className="responseText responseTextTitle">Nerdify</p>
            <p className="responseText">{value.response}</p>
          </div>
        </div>
      </Fragment>
    );
  }

  renderReplyTp() {
    const { source, value } = this.props;
    const { expanded } = this.state;

    if (source !== 'trustpilot' || !value.response || !expanded) {
      return null;
    }

    return (
      <div className="response">
        <img src={imgReply} className="imgReply" alt="" />
        <div className="responseContent">
          <p className="responseTitle">Reply from Nerdify</p>
          <p className="responseText">{value.date}</p>
          <p className="responseText">{value.response}</p>
        </div>
      </div>
    );
  }

  render() {
    const { level, source, onClick } = this.props;

    return (
      <div className={`cardItem level${level} ${source}`} onClick={onClick}>
        {this.renderContentFb()}
        {this.renderContentTp()}
        {this.renderReadMore()}
        {this.renderReplyFb()}
        {this.renderReplyTp()}
      </div>
    );
  }
}

export default Item;
