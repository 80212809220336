import React, { Component } from 'react';
import { NavLink, Link } from 'react-router-dom';
import Holder from '../../../../themes/nerdify/components/Holder';

import {
  HeaderClp3,
  HeaderIcon,
} from './../../../../themes/nerdify/components/Header';
import { HnavMob4 } from '../../blocks/HnavMob';
import { HnavClp3 } from '../../blocks/Hnav';
import { HeroClp3 } from '../../../../themes/nerdify/components/Hero';
import SaveTime, {
  SaveTimeIconImg,
} from '../../../../themes/nerdify/components/SaveTime';
import {OurPricesClp3} from '../../../../themes/nerdify/components/OurPrices';
import {PayListClp3} from '../../../../themes/nerdify/components/PayList';
import Text from '../../../../base_components/Text';
import {
  HiwClp3,
  HiwImg,
  HiwList,
  HiwItem,
  HiwText,
} from '../../../../themes/nerdify/components/Hiw';
import { slider1Items5 } from '../../blocks/SlickSlider1';
import {SlickSlider1Clp3} from '../../../../themes/nerdify/components/SlickSlider1';
//import SlickSliderClp3 from '../../../../themes/nerdify/components/SlickSlider2/SlickSliderClp3';
import { DemoCards1 } from '../../blocks/DemoCards';
import { demoChatItems } from "../../blocks/DemoChat";
import DemoChat from '../../../../themes/nerdify/components/DemoChat';
import { SectionClp3, Section12 } from '../../../../themes/nerdify/components/Section';
import { Intro5 } from '../../../../themes/nerdify/components/Intro';
import { SliderCombinedItems } from "../../blocks/SliderCombined";
import SliderCombined from '../../../../themes/nerdify/components/SliderCombined';
import {FooterClp3} from '../../blocks/Footer'
import Press, { PressList, PressItem, PressIcon } from '../../../../themes/nerdify/components/Press';
import { FAQItems2 } from "../../blocks/FAQ";
import { Faq3, Accordion, AccordionHead, AccordionBody, AccordionItem, AccordionIcon } from '../../../../themes/nerdify/components/Faq';

import { TextBack3 } from '../../blocks/TextBack';
import {
  NerdsClp3,
  NerdsList,
  NerdsItem,
} from '../../../../themes/nerdify/components/Nerds';
import {
  TopNerdsInfoClp3,
  TopNerdsInfoImg,
} from '../../../../themes/nerdify/components/TopNerdsInfo';
import DemoSubjects from '../../../../themes/nerdify/components/DemoSubjects';
import { useMobile } from '../../../../hooks';

import LocalesPage from '../LocalesPage';
//import {REACT_APP_SITENAME} from "../../../../config";

const WithHook = (props) => {
  const isMobile = useMobile();

  return props.children({
    isMobile
  });
};

class Landing2 extends Component {
  constructor(props) {
    super(props);
    this.openHiwModal = this.openHiwModal.bind(this);
    this.closeHiwModal = this.closeHiwModal.bind(this);
  }

  openHiwModal() {
    this.howItWorksModalRef.show();
  }

  closeHiwModal() {
    this.howItWorksModalRef.hide();
  }

  render() {
    const { pageCfg } = this.props;

    return (
      <WithHook>
        {({
          isMobile
        }) => {
          return (
            <LocalesPage {...this.props}>
              <div className="landing">

                <HeaderClp3>
                  <Holder>
                    <NavLink className='logo-link logo-link' to='/'>
                      <HeaderIcon iconName='logo-us' />
                    </NavLink>
                    <div className="header-wrap">
                      {isMobile ? <HnavMob4 /> : <HnavClp3 />}
                    </div>
                  </Holder>
                </HeaderClp3>

                <HeroClp3>
                  <div className="hero-main">
                    <div className="holder">
                      <h1 className="hero__title">{pageCfg.headline || <Text>Confused by your Homework?</Text>}</h1>
                      <h5 className="hero__subtitle">{pageCfg.subtitle || <Text>Get instant access to Nerds<br className="mobile-only" /> who can help</Text>}</h5>
                      <TextBack3 {...this.props} subTheme={7} submitBtnTxt={'Text me now'} />
                    </div>
                  </div>
                  <div className="hero-rate">
                    <div className="holder">
                      <div className="hero-rate-msg">76,750 users rated Nerds 4.7/5</div>
                      <div className="hero-rate-icon" />
                    </div>
                  </div>
                </HeroClp3>

                <HiwClp3 subTheme={1}>
                  <div className="holder hiw-holder1">

                  <h3 className='hiw-title'>Fast. Simple.<br className="mobile-only" /> Always with you.</h3>
                      <HiwList>
                        <HiwItem>
                          <HiwImg img={'ill-1@2x'} ext={'png'}/>
                          <HiwText>
                            <p className="p">Tell us what you need help<br className="desktop-only"/> with or send a pic with instructions</p>
                          </HiwText>
                        </HiwItem>
                        <HiwItem>
                          <HiwImg img={'ill-2@2x'} ext={'png'} />
                          <HiwText>
                            <p className="p">Get the best-fit Nerd<br className="desktop-only"/>
                              assigned for your task as soon
                              as possible</p>
                          </HiwText>
                        </HiwItem>
                        <HiwItem>
                          <HiwImg img={'ill-3@2x'} ext={'png'} />
                          <HiwText>
                            <p className="p">Agree to the quote and make<br className="desktop-only"/>
                              a secure payment through
                              the platform</p>
                          </HiwText>
                        </HiwItem>
                        <HiwItem>
                          <HiwImg img={'ill-4@2x'} ext={'png'} />
                          <HiwText>
                            <p className="p">Get help and become<br className="desktop-only"/> one more happy<br className="desktop-only"/> Nerdify client</p>
                          </HiwText>
                        </HiwItem>
                      </HiwList>

                    <div className="hiw-demochat">
                      <div className="hiw-el1"/>
                      <div className="hiw-el2"/>
                      <div className="hiw-el3"/>
                      <DemoChat items={ demoChatItems } />
                    </div>
                  </div>

                  <div className="hr_5" />

                  <div className="holder hiw-holder2">
                    <p className="p__tiny hiw-honor-line">
                      Nerds can do anything to help with your college or work life, <br className="desktop-only" />
                      as long as it's legal and doesn't violate any policy or our <Link className="link" to="/honor-code">Honor Code</Link>. See <Link className="link" to="/honor-code#prohibited-tasks">the list</Link> of prohibited tasks.</p>
                  </div>
                </HiwClp3>

                <Press className={"press__2 press__clp3"}>
                  <div className="press-wrapper">
                    <div className="press__title">Featured on</div>
                    <PressList>
                      <PressItem>
                        <PressIcon iconName={'forbes'} />
                      </PressItem>
                      <PressItem>
                        <a href='https://www.wired.com/story/xai-meeting-ai-chatbot/' target="_blank" rel="noopener noreferrer">
                          <PressIcon iconName={'wired'} />
                        </a>
                      </PressItem>
                      <PressItem>
                        <a href='https://tech.co/startups-redefining-educational-space-2017-04' target="_blank" rel="noopener noreferrer">
                          <PressIcon iconName={'tech-co'} />
                        </a>
                      </PressItem>
                      <PressItem>
                        <a
                          href='https://www.nextmedia-hamburg.de/wp-content/uploads/2018/12/nextMedia.Kompass_Trendreport_Oktober_2016.pdf' target="_blank" rel="noopener noreferrer">
                          <PressIcon iconName={'next-media'} />
                        </a>
                      </PressItem>
                      <PressItem>
                        <a href='https://venturebeat.com/2017/05/19/nerdify-announces-the-launch-of-nerdy-bot/' target="_blank" rel="noopener noreferrer">
                          <PressIcon iconName={'venturebeat'} />
                        </a>
                      </PressItem>
                    </PressList>
                  </div>
                </Press>

                <SaveTime className="save-time-block save-time-block__clp3">
                  <div className="holder">
                    <div className="h3 save-time-title">Save Time and Effort<br className="mobile-only"/> with Nerdify</div>
                    <div className="save-time-wrapper">

                      <ul className="save-time-list">
                        <li className="list-title">Spend less time...</li>
                        <li className="list-item">
                            <SaveTimeIconImg className="list-item-img" img="red-cross2.svg"/>
                          <div className="list-item-msg">No confusing forms to fill in</div>
                        </li>
                        <li className="list-item">
                          <SaveTimeIconImg className="list-item-img" img="red-cross2.svg"/>
                          <div className="list-item-msg">No lengthy search for the right freelancer</div>
                        </li>
                        <li className="list-item">
                          <SaveTimeIconImg className="list-item-img" img="red-cross2.svg"/>
                          <div className="list-item-msg">No stressful price negotiations</div>
                        </li>
                      </ul>

                      <ul className="save-time-list">
                        <li className="list-title">…get better results</li>
                        <li className="list-item">
                          <SaveTimeIconImg className="list-item-img" img="green-check2.svg"/>
                          <div className="list-item-msg">Instant reply to any request</div>
                        </li>
                        <li className="list-item">
                          <SaveTimeIconImg className="list-item-img" img="green-check2.svg"/>
                          <div className="list-item-msg">Just drop a photo, video or text with a task</div>
                        </li>
                        <li className="list-item">
                          <SaveTimeIconImg className="list-item-img" img="green-check2.svg"/>
                          <div className="list-item-msg">Live updates via SMS/Messenger for free</div>
                        </li>
                      </ul>

                    </div>
                  </div>
                </SaveTime>

                <Section12>
                  <TopNerdsInfoClp3>
                    <div className="holder">
                      <div className="top-nerds-block">
                        <div className="top-nerds__title">Nerds are TOP 2% freelancers in each field</div>
                        <div className="top-nerds-subtitle">They all are experts with proven knowledge and vast experience in their area, who passed a rigid examination process and agreed  to follow our <NavLink className="link" to="/honor-code">Honor Code</NavLink> when joining Nerdify platform.</div>
                        <div className="top-nerds-btn-block">
                          <NavLink className="btnClp3 btnClp3_1" to='/top-nerds'>Meet the Nerds</NavLink>
                          <NavLink className="btnClp3 btnClp3_2" to="/become">Apply to be a Nerd</NavLink>
                        </div>
                      </div>
                      <TopNerdsInfoImg className="top-nerds-img" img="top-nerds.png" img2x="top-nerds@2x.png" />
                    </div>
                  </TopNerdsInfoClp3>

                  <DemoSubjects className="demoSubjects_clp3"/>

                  <NerdsClp3 className="nerds_clp3">
                    <div className="holder">
                      <div className='nerds__title'>...from some of the TOP universities</div>
                      <NerdsList>
                        <NerdsItem />
                        <NerdsItem />
                        <NerdsItem />
                        <NerdsItem />
                        <NerdsItem />
                      </NerdsList>
                    </div>
                  </NerdsClp3>

                </Section12>

                <SlickSlider1Clp3 items={slider1Items5} className={'slick-slider1_clp3'} />

                <Intro5 indent={"pb40m pb30d"}>
                  <Holder className={"holder_3"}>
                    <div className="intro__title">Disclaimer</div>
                    <div className="intro__msg">Please be aware that Nerds will not agree to be engaged in the activity that violates any policy or our Honor Code. Please read our <NavLink to="/honor-code">Honor Code</NavLink> and <NavLink to="/honor-code#prohibited-tasks">the list</NavLink>  of prohibited tasks carefully.</div>
                  </Holder>
                </Intro5>


                <SliderCombined indent={"pt40m pt30d pb20d"} theme={'clp'}
                                title={<Text>Not sure what to start with?<br className="desktop-only"/> Take a look at the most popular requests!</Text>}
                                items={ SliderCombinedItems } />
{/*
                <SlickSliderClp3/>
*/}

                <SectionClp3>
                  <div className='section-title'>...or send your personal help request</div>
                  <TextBack3 {...this.props} subTheme={8} submitBtnTxt={'Text me now'} />
                </SectionClp3>

                <DemoCards1 />

                <OurPricesClp3 />

                <PayListClp3 />

                <Faq3>
                  <Holder>
                    <h3 className={'faq-title'}>FAQ</h3>
                    <Accordion>
                      {FAQItems2.map((item, index) => {
                        return (
                          <AccordionItem index={index} key={`faqItem${index}`}>
                            <AccordionHead>
                              <AccordionIcon icons={['plus2', 'minus2']} />
                              <h6 className='faq-question'>{item.Q}</h6>
                            </AccordionHead>
                            <AccordionBody>
                              <p className='p'>{item.A}</p>
                            </AccordionBody>
                          </AccordionItem>
                        )
                      })}
                    </Accordion>
                  </Holder>
                </Faq3>

                <FooterClp3 />

              </div>
            </LocalesPage>
          )
        }}
      </WithHook>
    )

  }
}

export default Landing2;
